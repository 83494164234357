html, body, #root {
    height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Used only for Drag & Drop. Copy drag implementation based on https://codesandbox.io/s/40p81qy7v0 */
.clone ~ div {
  transform: none !important;
}

.tabScroll::-webkit-scrollbar {
  display: none;
}

.lesson-content-react-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 360px;
}
